<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>가맹점별 현황</v-card-title>
        <v-card-text>내용</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AgencyStat',
  metaInfo: {
    title: '가맹점별 현황'
  },
  created() {
    this.$store.commit('setAppBarTitle', '가맹점별 현황')
  }
}
</script>
